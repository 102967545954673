import React,{ useEffect, useState} from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';


import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from 'axios';
import { bugs, website, server } from "variables/general.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import AssessmentIcon from '@material-ui/icons/Assessment';

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
  dailyTransChart,
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { CategoryOutlined } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function Dashboard() {

  const [totalTransaction, setTotalTransaction] = useState(null);
  const [totalAmt, setTotalAmt] = useState(null);
  const [totalCat, setTotalCat] = useState(null);
  const [totalTaxes, setTotalTaxes] = useState(null);
  const [totalItem, setTotalItem] = useState(null);
  
  const [loadingDashbord, setLoadingDashbord] = useState(true);
  const [weeklyRevenueData, setWeeklyRevenueData] = useState("");
  const [weeklyTransData, setWeeklyTransData] = useState("");
  const [accessPage, setAccessPage] = useState(1);  
  const getDashbordDetail = () =>
  {
      const data = {};
      const headers = {
        'Authorization': 'Bearer '+localStorage.getItem('merchantToken')
      }
      console.log("-------------------------------");
      console.log(localStorage.getItem('domain'));
      console.log("-------------------------------");
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'get_dashbord_detail', data,{
      headers: headers
    }).then(response => {
          //  console.log("ressss",response.data);
          setTotalTransaction(response.data.totalTransaction);
          if(response.data.totalAmount > 1000000)
          {
            var x = response.data.totalAmount / 1000000;
            setTotalAmt(parseInt(x)+" M");
          }else if(response.data.totalAmount > 1000)
          {
            var x = response.data.totalAmount / 1000;
            setTotalAmt(parseInt(x)+" K");
          }else{
            setTotalAmt(response.data.totalAmount);
          }
         
          setTotalCat(response.data.totalCategory);
          setTotalTaxes(response.data.totalTaxes);
          setTotalItem(response.data.totalItem);          
          setLoadingDashbord(false);
          var x = [];
          var transData=[];
          var max = 0;
          var transMax = 0;
          response.data.weeklyChartData.map(function(obj,idx) { 
            x[idx] = obj.daily_total;
            transData[idx] = obj.total_rec;
            if(parseFloat(max) < parseFloat(obj.daily_total))
            {
              max = obj.daily_total;
            }
            if(parseInt(transMax)<parseInt(obj.total_rec))
            {
              transMax = parseInt(obj.total_rec);
            }
          });
          
          dailySalesChart.options.high=max;
          dailyTransChart.options.high = transMax;
          setWeeklyRevenueData({
            labels: ["M", "T", "W", "T", "F", "S", "S"],
            series: [x],
          }); 
          setWeeklyTransData({
            labels: ["M", "T", "W", "T", "F", "S", "S"],
            series: [transData],
          }); 
          
          
        }) .catch(error => {                    
          return error;
        });
  }
  useEffect(() => {
    var is_location_active = localStorage.getItem('is_active');    
    if(is_location_active === "0")
    {      
      window.location.href = "/in_active";
      return;  
    }
    
    const permissonData = JSON.parse(localStorage.getItem('permissonData'));   
       
       if(permissonData)
       {
         if(permissonData.access_to_online_dashboard === 0)
         {
           setAccessPage(0);
           return;
         }
       }

       
     getDashbordDetail();

  }, []);

  const classes = useStyles();

  if( accessPage === 0)
  { 
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Dashboard</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3><div>You do not have permission to access this page.</div> <div>Please contact an administrator.</div><div>Thank you.</div></h3>
                  
                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }
  
  if(loadingDashbord)
  {
  return (
    <center>
    <h3>Loading...</h3>
    <CircularProgress id="loginProgress" ></CircularProgress>
  </center>
  );
  } 
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <AssessmentIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Transactions</p>
              <h3 className={classes.cardTitle}>
              {(totalTransaction != null)?totalTransaction:<CircularProgress className={"MuiCircularProgress-circleDisableShrink"}/>}  </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                {/*<Danger>
                  <Warning />
                </Danger>
                 <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Get more space
                </a> */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <AttachMoneyIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Revenue</p>
              <h3 className={classes.cardTitle}>{(totalAmt != null)?"$"+totalAmt:<CircularProgress className={"MuiCircularProgress-circleDisableShrink"}/>}</h3>
            </CardHeader>
            <CardFooter stats>
              {/* <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div> */}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <CategoryOutlined></CategoryOutlined>
              </CardIcon>
              <p className={classes.cardCategory}>Total Categories</p>
              <h3 className={classes.cardTitle}>{(totalCat != null)?totalCat:<CircularProgress className={"MuiCircularProgress-circleDisableShrink"}/>}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                {/* <LocalOffer />
                Tracked from Github */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <DynamicFeedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Items</p>
              <h3 className={classes.cardTitle}>{(totalItem != null)?totalItem:<CircularProgress className={"MuiCircularProgress-circleDisableShrink"}
              />}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                {/* <Update />
                Just Updated */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={weeklyRevenueData}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Last Week Revenue</h4>
              {/* <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p> */}
            </CardBody>
            {/* <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
       
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={weeklyTransData}
                type="Line"
                options={dailyTransChart.options}
                listener={dailyTransChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Last Week Transactions</h4>
              {/* <p className={classes.cardCategory}>Last Campaign Performance</p> */}
            </CardBody>
            {/* <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Email Subscriptions</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}
      </GridContainer>
      
    </div>
  );
}
